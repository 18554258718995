<template>
  <div class="register">
    <h1>绑定微信</h1>
    <div>
      <label>微信用户基本信息</label>
      {{ wxUserInfo }}
    </div>
  </div>
</template>
<script>
import { Field, Form, Button, CountDown } from "vant";
import { demotoken, demoMsg } from "@/api/axios_login.js";
export default {
  data () {
    return {
      wxUserInfo: null,
    };
  },
  components: {
    [Field.name]: Field,
    [Form.name]: Form,
    [CountDown.name]: CountDown,
    [Button.name]: Button,
  },
  computed: {},
  methods: {
    getWxUser () {
      let user = this.defineMethods.getCookie("WxUserInfo");
      if (!this.defineMethods.isNullOrEmpty(user)) {
        this.wxUserInfo = user;
        return;
      }
      user = {
        openid: "opoU41ty0CjY5DvVOUULW8s6UvyY",
        nickname: "陈小哥(固定值)",
        sex: "1",
        province: "湖北",
        city: "黄冈",
        country: "中国",
        headimgurl:
          "http://thirdwx.qlogo.cn/mmopen/trlvodCrhjichQ0L828BD0nhanwffEiaPAYTLsgZiaFmtP7GxJ86stD2jVxibUGZsAfecsHTichq4KJLNvl6ibDTwlGpIIdj5f0yUe/132",
      };
      this.defineMethods.setCookie("WxUserInfo", JSON.stringify(user));
    }
  },
  mounted () {
    // this.$router.push({ path: "/", query: { code: "login" } });
    this.getWxUser();
  },
};
</script>
<style lang="scss" scoped>
.register {
  width: 100%;
  height: 100%;
  &-title {
    height: 41px;
    line-height: 41px;
    width: 100%;
    background: #f2f6fc;
    font-size: 14px;
    font-weight: 400;
    color: #409eff;
    span {
      margin-left: 4px;
    }
    i {
      font-size: 10px;
      font-weight: bold;
    }
  }
  .scroll-container {
    width: 100%;
    height: calc(100% - #{"41PX"});
  }
}
</style>
<style lang="scss">
.register .get-code {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  > div {
    margin-left: 5px;
    color: #c0c4cc;
  }
}
.register .get-code,
.register .van-field__left-icon .van-icon,
.register .van-field__right-icon .iconfont {
  color: #409eff;
}
</style>
